import { CommonModule } from "@angular/common";
import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { BehaviorSubject, of } from "rxjs";
import { CFSTemplateType, Template, TemplatePreviewOptions, TemplatePreviewType, TemplateProperties } from "../../../models";
import { DigitalAssetGetDto, DigitalAssetScreenSize, DigitalAssetType, MediaContentDto } from "clearline-api";
import { HtmlTemplateComponent } from "../html-template";
import { LottieTemplateComponent } from "../lottie-template";
import { defaultCfsTemplateMediaConfig } from "../cfs-template.config";
import { VideoTemplateComponent } from "../video-template";
import { CfsTemplateUtils } from "../cfs-template.utils";
import { take } from "rxjs/operators";
import { TemplatePreviewService } from "../template-preview.service";

interface SetTemplateTypeParams {
  parsedTemplateType?: CFSTemplateType | null;
  mediaContent?: MediaContentDto | null;
  digitalAsset?: DigitalAssetGetDto | null;
}

@Component({
  selector: "lib-cfs-template-preview",
  templateUrl: "./cfs-template-preview.component.html",
  styleUrls: ["./cfs-template-preview.component.scss"],
  standalone: true,
  imports: [CommonModule, LottieTemplateComponent, HtmlTemplateComponent, VideoTemplateComponent]
})
export class CfsTemplatePreviewComponent {
  @Input() index = 0;
  @Input() previewType: TemplatePreviewType = TemplatePreviewType.Active;
  @Input() fullScreen = true;
  @Input() isPrintView = false;
  @Input() scalable = true;
  @Input() responsive = false;
  @Input() isLowResolutionScreen = false;
  @Input() fullMediaConfig = defaultCfsTemplateMediaConfig;

  @Input() set screenSize(screenSize: DigitalAssetScreenSize) {
    this.setScreenSize(screenSize || DigitalAssetScreenSize.Full);
  }

  @Input() set template(template: Template | null) {
    if (template) {
      console.log("template", template); // todo: remove after testing
      const { generatedBody, templateProperties } = <Template>template;

      const parsedTemplateType: CFSTemplateType | null = this.getParsedTemplateType(generatedBody as string);
      this.templateProperties = templateProperties || null;
      this.generatedBody = generatedBody ? this.sanitizer.bypassSecurityTrustHtml(generatedBody as string) : "";
      this.displayTime = CfsTemplateUtils.getDisplayTimeByTemplate(template);

      if (parsedTemplateType === CFSTemplateType.Lottie) {
        const previewOptions$ = template?.previewOptions ? of(template.previewOptions) : this.templatePreviewService.getLottieOptions(template);

        previewOptions$.pipe(take(1)).subscribe((previewOptions: TemplatePreviewOptions | null) => {
          const { mediaContent } = previewOptions || {};
          this.isScreenSizeResponsive = CfsTemplateUtils.getIsMediaScreenSizeResponsive(mediaContent);

          this.setTemplateType({ parsedTemplateType, mediaContent });
          this.previewOptions$.next(previewOptions);
          this.setScreenSize();
        });
      } else {
        this.setTemplateType({ parsedTemplateType });
      }
    }
  }

  @Input() set options(options: TemplatePreviewOptions | null) {
    console.log("preview template options", options); // todo: remove after testing
    if (options) {
      const { mediaContent, htmlBody, digitalAsset } = options;
      const parsedTemplateType: CFSTemplateType | null = this.getParsedTemplateType(htmlBody || "");

      this.setTemplateType({ parsedTemplateType, mediaContent, digitalAsset });
      this.previewOptions$.next(options);
      console.log("options", options); // todo: remove after testing

      if (htmlBody) {
        this.generatedBody = this.sanitizer.bypassSecurityTrustHtml(htmlBody);
      }
    }
  }

  @Output() printReady = new EventEmitter();
  @Output() ready = new EventEmitter();

  @ViewChild(VideoTemplateComponent) videoTemplateComponent?: VideoTemplateComponent;

  readonly DigitalAssetType = DigitalAssetType;
  readonly CFSTemplateType = CFSTemplateType;

  templateType: CFSTemplateType | null = null;
  templateProperties: TemplateProperties | null = null;
  templateScreenSize: DigitalAssetScreenSize = DigitalAssetScreenSize.Full;
  displayTime = 0;
  generatedBody: SafeHtml = "";

  previewOptions$ = new BehaviorSubject<TemplatePreviewOptions | null>(null);
  templateScreenSize$ = new BehaviorSubject<DigitalAssetScreenSize>(this.templateScreenSize);
  templateType$ = new BehaviorSubject<CFSTemplateType | null>(null);

  private isScreenSizeResponsive = false;

  @HostListener("window:resize") handleResizeEvent(): void {
    this.setScreenSize();
  }

  constructor(private sanitizer: DomSanitizer, private templatePreviewService: TemplatePreviewService) {}

  ngAfterViewInit(): void {
    if (this.templateType !== CFSTemplateType.Video) {
      this.ready.emit();
    }
  }

  play(): void {
    if (this.videoTemplateComponent) {
      this.videoTemplateComponent.play();
    }
  }

  stop(): void {
    if (this.videoTemplateComponent) {
      this.videoTemplateComponent.stop();
    }
  }

  onVideoTemplateReady(): void {
    this.ready.emit();
  }

  onLaunchPrint(): void {
    if (this.isPrintView) {
      this.printReady.emit();
    }
  }

  private setTemplateType({ mediaContent, digitalAsset, parsedTemplateType }: SetTemplateTypeParams): void {
    const resultDigitalAsset: DigitalAssetGetDto | null = digitalAsset || mediaContent?.digitalAssets[0] || null;
    const assetType: DigitalAssetType | null = resultDigitalAsset?.digitalAssetType || null;
    this.templateType = assetType === DigitalAssetType.Html ? CFSTemplateType.MultiContent : assetType === DigitalAssetType.Lottie ? CFSTemplateType.Lottie : parsedTemplateType || null;
    console.log("this.templateType", this.templateType); // todo: remove after testing

    this.templateType$.next(this.templateType);
  }

  private setScreenSize(screenSize: DigitalAssetScreenSize | null = null): void {
    const updatedScreenSize: DigitalAssetScreenSize = screenSize ?? this.getCurrentScreenSizeType();

    if (updatedScreenSize && this.templateScreenSize !== updatedScreenSize) {
      this.templateScreenSize = updatedScreenSize ?? DigitalAssetScreenSize.Full;

      this.templateScreenSize$.next(this.templateScreenSize);
    }
  }

  private getCurrentScreenSizeType(): DigitalAssetScreenSize {
    return this.isScreenSizeResponsive ? CfsTemplateUtils.getScreenSizeType(this.isLowResolutionScreen) : DigitalAssetScreenSize.Full;
  }

  private getParsedTemplateType(generatedBody: string): CFSTemplateType | null {
    const temporaryDiv: HTMLElement = document.createElement("div");
    temporaryDiv.innerHTML = generatedBody;
    const templateElement: HTMLElement | null = temporaryDiv.querySelector(".template");

    if (templateElement) {
      const classList: DOMTokenList = templateElement.classList;

      for (const type in CFSTemplateType) {
        if (classList.contains(CFSTemplateType[type as keyof typeof CFSTemplateType])) {
          return CFSTemplateType[type as keyof typeof CFSTemplateType];
        }
      }
    }

    return null;
  }
}
