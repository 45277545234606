<div
  *ngIf="templateType$ | async as templateType"
  class="template-preview"
  [class.full-screen]="fullScreen">
  <ng-container [ngSwitch]="templateType">
    <lib-lottie-template
      *ngSwitchCase="CFSTemplateType.Lottie"
      [isPrintView]="isPrintView"
      [previewType]="previewType"
      [screenSize]="templateScreenSize$ | async"
      [options]="previewOptions$ | async"
      (printReady)="onLaunchPrint()">
    </lib-lottie-template>

    <lib-html-template
      *ngSwitchCase="CFSTemplateType.MultiContent"
      [previewType]="previewType"
      [isPrintView]="isPrintView"
      [screenSize]="templateScreenSize$ | async"
      [options]="previewOptions$ | async"
      [scalable]="scalable"
      [responsive]="responsive"
      [isLowResolutionScreen]="isLowResolutionScreen"
      (printReady)="onLaunchPrint()">
    </lib-html-template>

    <lib-video-template
      *ngSwitchCase="CFSTemplateType.Video"
      [previewType]="previewType"
      [mediaConfig]="fullMediaConfig"
      [innerHTML]="generatedBody"
      [templateProperties]="templateProperties"
      [displayTime]="displayTime"
      [index]="index"
      (ready)="onVideoTemplateReady()">
    </lib-video-template>

    <div
      *ngSwitchDefault
      class="template__wrapper"
      [class.for-print]="isPrintView"
      [innerHTML]="generatedBody"
    ></div>
  </ng-container>
</div>
