import { SafeHtml } from "@angular/platform-browser";
import {
  DefaultTemplateType,
  DigitalAssetGetDto,
  DigitalAssetScreenSize,
  GlobalTemplateType,
  KeyValueAsset,
  MediaContentDto,
  TemplateConfigurationTriggerActionType,
  TemplateConfigurationTriggerAreaType,
  TemplateConfigurationTriggerType,
  TemplateDestinationType,
  TemplateImageSourceType,
  TemplateLogoSourceType
} from "clearline-api";

export interface Template {
  id?: number;
  templateId: number;
  name: string;
  templateName?: string;
  widgetId?: number;
  defaultTemplateId?: number;
  templateType?: DefaultTemplateType;
  globalTemplateType: GlobalTemplateType;
  isDefault?: boolean;
  canEnable?: boolean;
  enabled: boolean;
  templateProperties?: TemplateProperties;
  configuration?: TemplateConfigurationDto;
  generatedBody?: string | SafeHtml;
  previewOptions?: TemplatePreviewOptions;
  redirectSettings?: TemplateInteractionRedirectConfigModel;
  isSplashScreen?: boolean;
}

export interface TemplateInteractionRedirectConfigModel {
  redirectPageUrl?: string;
  timeoutInSeconds: number;
}

export interface TemplateConfigurationDto {
  mediaContentId?: number;
  defaultParameters: KeyValueAsset;
  options?: TemplateConfigurationOptionsDto;
  triggers?: TemplateConfigurationTriggerDto[];
}

export interface TemplateConfigurationOptionsDto {
  defaultDestination?: TemplateDestinationDto;
  displayTime?: number;
  image: TemplateImageConfigurationDto;
  logo: TemplateLogoConfigurationDto;
}

export interface TemplateImageConfigurationDto {
  sourceType: TemplateImageSourceType;
}

export interface TemplateLogoConfigurationDto {
  sourceType: TemplateLogoSourceType;
  brandId?: number;
}

export interface TemplateConfigurationTriggerDto {
  triggerType: TemplateConfigurationTriggerType;
  area: TemplateConfigurationTriggerAreaDto;
  action: TemplateConfigurationTriggerActionDto;
}

export interface TemplateConfigurationTriggerAreaDto {
  areaType: TemplateConfigurationTriggerAreaType;
}

export interface TemplateConfigurationTriggerActionDto {
  actionType: TemplateConfigurationTriggerActionType;
  destination: TemplateCustomDestinationDto;
}

export interface TemplateDestinationDto {
  destinationType: TemplateDestinationType;
  url?: string;
  widgetType?: number;
}

export interface TemplateCustomDestinationDto {
  useDefaultDestination: boolean;
  customDestination?: TemplateDestinationDto;
}

export interface ContentSettings {
  assetId?: number;
  isPredefined: boolean;
  templateCategory: string;
  displayContent: string;
  backgroundColor: string;
  buttonColor: string;
  campaignSchedule: string;
}

export interface UrlContentSettings {
  ContentType: CustomContentTemplateType;
  VideoContentType?: VideoTemplateContentType;
  ImageUrl: string;
  VideoUrl?: string;
  ContentDuration?: number;
}

export enum VideoTemplateContentType {
  Import = "Import",
  YouTube = "YouTube",
  Vimeo = "Vimeo"
}

export interface CouponContentSettings {
  CouponUrl: string;
  CouponName: string;
  CouponId: string;
}

export interface TemplateExtendedProperties {
  LottieJson?: string;
  CouponName?: string;
  ShortLinkUrl?: string;
  LocationId?: string;
}

export interface TemplateBasePreviewOptions {
  digitalAsset?: DigitalAssetGetDto;
  json?: string;
  screenSizesJsonAsset?: ScreenSizesJsonAsset;
  mediaContent?: MediaContentDto;
}

export interface TemplatePreviewOptions extends TemplateBasePreviewOptions {
  htmlBody?: string;
  previewTitle?: string;
  previewIcon?: string;
  contentSettings?: ContentSettings;
  defaultParameters?: KeyValueAsset;
  isScreenSizeResponsive?: boolean;
}

export interface QrCodeContentSettings {
  backgroundColor?: string;
  backgroundImage?: string;
  logoUrl?: string;
  imageUrl?: string;
  qrCodePrimaryColor?: string;
  qrCodeSecondaryColor?: string;
  qrCodeBackground?: string;
  qrCodeHasFrame?: boolean;
  qrCodeLogo?: string;
  qrCodeLogoType?: DisplayQrCodeLogoTypes;
  qrCodeUrl?: string;
  buttonText?: string;
  buttonWidth?: number;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  qrActionText?: string;
  qrActionColor?: string;
  subtitleText?: string;
  subtitleColor?: string;
  titleText?: string;
  titleColor?: string;
}

export interface TemplateDestinationDto {
  destinationType: TemplateDestinationType;
  separateUrl?: boolean;
  url?: string;
  widgetType?: number;
  externalCouponId?: string;
}

export interface TemplateCustomDestinationDto {
  useDefaultDestination: boolean;
  customDestination?: TemplateDestinationDto;
}

export interface CfsTemplateMediaConfig {
  autoplay?: boolean;
  muted?: boolean;
  loop?: boolean;
  fullscreen?: boolean;
  coverParent?: boolean;
}

export type TemplateProperties = ContentSettings | UrlContentSettings | CouponContentSettings | TemplateExtendedProperties | null;

export type ScreenSizesJsonAsset = {
  [size in DigitalAssetScreenSize]?: string;
};

export enum DisplayQrCodeLogoTypes {
  None = 0,
  BrandLogo = 1,
  CustomLogo = 2,
  DisplayQrCodeLogo = 3
}

export enum AssetParamKeys {
  MainBackgroundColor = "%%MAIN_BG_COLOR%%",
  ButtonBackgroundColor = "%%BUTTON_BG_COLOR%%",
  MainBackgroundColorHex = "%%MAIN_BG_COLOR_HEX%%",
  ButtonBackgroundColorHex = "%%BUTTON_BG_COLOR_HEX%%",
  Param1 = "%%PARAM_1%%",
  Param2 = "%%PARAM_2%%",
  Param3 = "%%PARAM_3%%",
  Param4 = "%%PARAM_4%%",
  Param5 = "%%PARAM_5%%",
  Param6 = "%%PARAM_6%%"
}

export enum ContentSettingKeys {
  BackgroundColor = "backgroundColor",
  ButtonColor = "buttonColor",
  CampaignSchedule = "campaignSchedule",
  TemplateCategory = "templateCategory"
}

export enum CfsContentAsset {
  MainBackgroundColor = "%%MAIN_BG_COLOR%%",
  ButtonBackgroundColor = "%%BUTTON_BG_COLOR%%",
  MainBackgroundColorHex = "%%MAIN_BG_COLOR_HEX%%",
  ButtonBackgroundColorHex = "%%BUTTON_BG_COLOR_HEX%%",
  Param1 = "%%PARAM_1%%",
  Param2 = "%%PARAM_2%%",
  Param3 = "%%PARAM_3%%",
  Param4 = "%%PARAM_4%%",
  Param5 = "%%PARAM_5%%",
  Param6 = "%%PARAM_6%%"
}

export enum CfsQrCodeContentAsset {
  backgroundColor = "%%BACKGROUND_COLOR%%",
  backgroundImage = "%%BACKGROUND_IMAGE%%",
  logoUrl = "%%LOGO_URL%%",
  imageUrl = "%%IMAGE_URL%%",
  qrCodePrimaryColor = "%%QR_CODE_PRIMARY_COLOR%%",
  qrCodeSecondaryColor = "%%QR_CODE_SECONDARY_COLOR%%",
  qrCodeBackground = "%%QR_CODE_BACKGROUND%%",
  qrCodeHasFrame = "%%QR_CODE_HAS_FRAME%%",
  qrCodeLogo = "%%QR_CODE_LOGO%%",
  qrCodeLogoType = "%%QR_CODE_LOGO_TYPE%%",
  qrCodeUrl = "%%QR_CODE_URL%%",
  buttonText = "%%BUTTON_TEXT%%",
  buttonWidth = '"%%BUTTON_WIDTH%%"',
  buttonTextColor = '"%%BUTTON_TEXT_COLOR%%"',
  buttonBackgroundColor = '"%%BUTTON_BACKGROUND_COLOR%%"',
  qrActionText = "%%QR_ACTION_TEXT%%",
  qrActionColor = '"%%QR_ACTION_COLOR%%"',
  subtitleText = "%%SUBTITLE_TEXT%%",
  subtitleColor = '"%%SUBTITLE_COLOR%%"',
  titleText = "%%TITLE_TEXT%%",
  titleColor = '"%%TITLE_COLOR%%"'
}

export type HtmlTemplateOptions = Record<string, string>; // todo: add more specific interface

export enum CustomContentTemplateType {
  Image = "Image",
  Video = "Video"
}

export enum TemplatePreviewType {
  Active = "Active",
  Inactive = "Inactive"
}

export enum CFSTemplateType {
  Lottie = "lottie",
  Video = "video",
  Image = "image",
  MultiContent = "multiContent",
  SplashScreen = "splashScreen"
}

export type ResponsiveAssetsInfo = {
  [screenSize in DigitalAssetScreenSize]?: DigitalAssetGetDto | null;
};
