import { AssetParamKeys, CfsContentAsset, CfsQrCodeContentAsset, ContentSettingKeys, QrCodeContentSettings, ResponsiveAssetsInfo, Template, TemplateCustomDestinationDto, TemplateDestinationDto, TemplatePreviewOptions, TemplateProperties, UrlContentSettings } from "../../models/cfs-template";
import { DigitalAssetGetDto, DigitalAssetScreenSize, KeyValueAsset, KeyValueDto, MediaContentDto } from "clearline-api";

type ContentAssetByKeys = {
  [key in AssetParamKeys]: string;
};

enum LetterWidthCategory {
  narrow = "narrow",
  medium = "medium",
  wide = "wide"
}

export class CfsTemplateUtils {
  static backgroundImageKey = "%%BACKGROUND_IMAGE%%";
  static contentAssetByKeys: ContentAssetByKeys = {
    [AssetParamKeys.MainBackgroundColor]: ContentSettingKeys.BackgroundColor,
    [AssetParamKeys.ButtonBackgroundColor]: ContentSettingKeys.ButtonColor,
    [CfsContentAsset.MainBackgroundColorHex]: "",
    [CfsContentAsset.ButtonBackgroundColorHex]: "",
    [AssetParamKeys.Param1]: "",
    [AssetParamKeys.Param2]: ContentSettingKeys.CampaignSchedule,
    [AssetParamKeys.Param3]: ContentSettingKeys.TemplateCategory,
    [AssetParamKeys.Param4]: "",
    [AssetParamKeys.Param5]: "",
    [AssetParamKeys.Param6]: ""
  };

  // todo: convert these utils to use pure functions instead of static methods

  static getScreenSizeType(isLowResolutionScreen: boolean, withTablet = false): DigitalAssetScreenSize {
    const smallScreen = 768;
    const mobileScreen = 576;
    let updatedScreenSize: DigitalAssetScreenSize = DigitalAssetScreenSize.Full;

    if (!isLowResolutionScreen) {
      if (window.innerWidth <= smallScreen) {
        if (withTablet) {
          updatedScreenSize = window.innerWidth <= mobileScreen ? DigitalAssetScreenSize.Compact : DigitalAssetScreenSize.Tablet;
        } else {
          updatedScreenSize = DigitalAssetScreenSize.Compact;
        }
      } else if (window.innerHeight >= window.innerWidth) {
        updatedScreenSize = DigitalAssetScreenSize.Vertical;
      }
    }

    return updatedScreenSize;
  }

  static getDisplayTimeByTemplate(template: Template): number {
    return template?.configuration?.options?.displayTime ?? 0;
  }

  static getContentDurationByTemplate(template: Template): number {
    const templateProperties: TemplateProperties | null = template?.templateProperties || null;

    return Number((templateProperties as UrlContentSettings)?.ContentDuration) ?? 0;
  }

  static getIsMediaScreenSizeResponsive(mediaContent?: MediaContentDto): boolean {
    return (
      mediaContent?.digitalAssets?.some(
        (item: DigitalAssetGetDto) => item.configuration?.screenSize && item.configuration.screenSize !== DigitalAssetScreenSize.Full
      ) || false
    );
  }

  static hexToRgb(hex: any) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m: any, r: any, g: any, b: any) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null;
  }

  static getLottieColorByRgb(rgb: any): string {
    return Object.keys(rgb)
      .map((key: string) => {
        const rgbItem = rgb[key];
        const lottieColorPart = rgbItem ? rgbItem / 255 : 0;
        return `${lottieColorPart}`;
      })
      .join(",");
  }

  static rgbToHex(rgbArray: [number, number, number], maxColorValue = 255): string {
    const hexComponents: string[] = rgbArray.map((value) => {
      const hex = Math.round(maxColorValue * value).toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    });

    return hexComponents?.length ? `#${hexComponents.join("")}` : "";
  }

  static getLottieColorByHex(hex: string): string {
    const rgb = CfsTemplateUtils.hexToRgb(hex);

    return rgb ? CfsTemplateUtils.getLottieColorByRgb(rgb) : "";
  }

  static getLottieColorListByHex(hex: string): string {
    return `[${CfsTemplateUtils.getLottieColorByHex(hex)}]`;
  }

  static lottieColorToRgb(lottieColor: [number, number, number]): { r: number; g: number; b: number } {
    return {
      r: Math.round(lottieColor[0] * 255),
      g: Math.round(lottieColor[1] * 255),
      b: Math.round(lottieColor[2] * 255)
    };
  }

  static lottieColorToHex(lottieColorString: string): string {
    const lottieColor = CfsTemplateUtils.parseLottieColor(lottieColorString);

    if (!lottieColor.length) {
      return "";
    }

    const rgb = CfsTemplateUtils.lottieColorToRgb(lottieColor);

    return CfsTemplateUtils.rgbToHex([rgb.r, rgb.g, rgb.b], 1);
  }

  static parseLottieColor(colorString: string): [number, number, number] | [] {
    return colorString ? (JSON.parse(colorString) as [number, number, number]) : [];
  }

  static getValueWithFirstUpperLetter(value: string): string {
    if (value && typeof value === "string") {
      const firstLetter: string = value.slice(0, 1).toUpperCase();
      const restValue: string = value.slice(1);

      return `${firstLetter}${restValue}`;
    }

    return "";
  }

  static getKeyValueAsset(params: KeyValueDto[] = []): KeyValueAsset {
    return params?.reduce((acc: KeyValueAsset, item: KeyValueDto) => {
      const { key, value } = item;
      acc[key] = value;

      return acc;
    }, {} as KeyValueAsset);
  }

  static getQrCodeContentAsset(qrCodeContentSettings: QrCodeContentSettings): KeyValueAsset {
    if (!qrCodeContentSettings) return {};

    return Object.keys(qrCodeContentSettings).reduce((acc: KeyValueAsset, key: string) => {
      const assetKey = key as keyof typeof CfsQrCodeContentAsset;

      if (CfsQrCodeContentAsset[assetKey]) {
        acc[CfsQrCodeContentAsset[assetKey]] = CfsTemplateUtils.serializeQrCodeSettingValue(assetKey, qrCodeContentSettings[assetKey]);
      }

      return acc;
    }, {} as KeyValueAsset);
  }

  static parseQrCodeContentAsset(templateDefaultParameters: KeyValueAsset): QrCodeContentSettings {
    if (!templateDefaultParameters) return {};

    const cfsQrCodeContentAssetEnumKeyByValue: { [key: string]: keyof typeof CfsQrCodeContentAsset } = {};

    for (const key in CfsQrCodeContentAsset) {
      if (CfsQrCodeContentAsset.hasOwnProperty(key)) {
        const assetKey = key as keyof typeof CfsQrCodeContentAsset;
        cfsQrCodeContentAssetEnumKeyByValue[CfsQrCodeContentAsset[assetKey]] = assetKey;
      }
    }

    return Object.keys(templateDefaultParameters).reduce((acc: QrCodeContentSettings, key: string) => {
      const enumKey = cfsQrCodeContentAssetEnumKeyByValue[key];
      if (enumKey) {
        (acc[enumKey] as QrCodeContentSettings[keyof QrCodeContentSettings]) = CfsTemplateUtils.parseQrCodeSettingValue(
          enumKey,
          templateDefaultParameters[key]
        );
      }
      return acc;
    }, {} as QrCodeContentSettings);
  }

  static parseQrCodeSettingValue(key: keyof QrCodeContentSettings, value: string): QrCodeContentSettings[keyof QrCodeContentSettings] {
    switch (key) {
      case "backgroundColor":
      case "buttonTextColor":
      case "buttonBackgroundColor":
      case "subtitleColor":
      case "qrActionColor":
      case "qrCodePrimaryColor":
      case "qrCodeSecondaryColor":
      case "qrCodeBackground":
      case "titleColor": {
        return CfsTemplateUtils.rgbToHex(JSON.parse(value));
      }
      case "qrCodeHasFrame": {
        return value === "true";
      }
      case "buttonWidth":
      case "qrCodeLogoType": {
        return +value;
      }
      default:
        return value;
    }
  }

  static serializeQrCodeSettingValue(key: keyof QrCodeContentSettings, value: QrCodeContentSettings[keyof QrCodeContentSettings]): string {
    switch (key) {
      case "backgroundColor":
      case "buttonTextColor":
      case "buttonBackgroundColor":
      case "subtitleColor":
      case "qrActionColor":
      case "qrCodePrimaryColor":
      case "qrCodeSecondaryColor":
      case "qrCodeBackground":
      case "titleColor": {
        return CfsTemplateUtils.getLottieColorListByHex(value as string);
      }
      case "qrCodeHasFrame":
      case "qrCodeLogoType": {
        return value ? value.toString() : "";
      }
      case "buttonWidth": {
        return value || value === 0 ? `${+value}` : "";
      }
      default:
        return value as string;
    }
  }

  static parseQrCodeBackgroundColor(defaultParameters?: KeyValueAsset): string {
    if (defaultParameters && defaultParameters[CfsQrCodeContentAsset.backgroundColor]) {
      return CfsTemplateUtils.parseQrCodeSettingValue(
        "backgroundColor",
        defaultParameters[CfsQrCodeContentAsset.backgroundColor]
      ) as string;
    }
    return "";
  }

  static GetDestination(
    triggerDestination?: TemplateCustomDestinationDto,
    defaultDestination?: TemplateDestinationDto
  ): TemplateDestinationDto | undefined {
    if (!triggerDestination || triggerDestination.useDefaultDestination) return defaultDestination;
    return triggerDestination.customDestination;
  }

  static getLottieTextWidth(text: string): number {
    let width = 0;

    for (let i = 0; i < text.length; i++) {
      width += CfsTemplateUtils.getLetterWidth(text[i]);
    }

    return width;
  }

  static parseHtmlQrCodeContentAsset(allParams: KeyValueAsset, isPrintView = false): Record<string, string> {
    const colorsList = [
      "backgroundColor",
      "buttonTextColor",
      "buttonBackgroundColor",
      "subtitleColor",
      "qrActionColor",
      "qrCodePrimaryColor",
      "qrCodeSecondaryColor",
      "qrCodeBackground",
      "titleColor"
    ];
    const qrCodeContentAsset: Record<string, string> = {};

    Object.keys(CfsQrCodeContentAsset).forEach((key) => {
      const assetKey = CfsQrCodeContentAsset[key as keyof typeof CfsQrCodeContentAsset];
      const value = allParams[assetKey];

      if (value) {
        const formattedKey = key.replace(/"/g, "");

        if (formattedKey === "backgroundColor" && isPrintView) {
          qrCodeContentAsset[formattedKey] = "#ffffff";
        } else if (formattedKey === "backgroundImage" && isPrintView) {
          qrCodeContentAsset[formattedKey] = "";
        } else {
          const handledValue = colorsList.includes(formattedKey) ? CfsTemplateUtils.lottieColorToHex(value) : value;
          qrCodeContentAsset[formattedKey] = handledValue;
        }
      }
    });

    return qrCodeContentAsset;
  }

  static getAllKeyValueAssetParams(options?: TemplatePreviewOptions, digitalDefaultParams: KeyValueDto[] = []): KeyValueAsset {
    const { defaultParameters, mediaContent } = options || {};

    const assetParameters: KeyValueAsset = CfsTemplateUtils.getKeyValueAsset(digitalDefaultParams);
    const mediaParams: KeyValueAsset = CfsTemplateUtils.getKeyValueAsset(mediaContent?.configuration.defaultParameters);

    return { ...assetParameters, ...mediaParams, ...defaultParameters };
  }

  static getResponsiveAssetsInfo(options: TemplatePreviewOptions): ResponsiveAssetsInfo {
    const { digitalAsset, mediaContent } = options;

    if (mediaContent) {
      const digitalAssets = mediaContent.digitalAssets;

      return Object.values(DigitalAssetScreenSize).reduce((acc: ResponsiveAssetsInfo, size: string) => {
        const itemDigitalAsset: DigitalAssetGetDto | undefined = digitalAssets.find((item: DigitalAssetGetDto) => {
          const itemScreenSize = item.configuration?.screenSize;
          return itemScreenSize === size || (!itemScreenSize && size === DigitalAssetScreenSize.Full);
        });

        return {
          ...acc,
          [size]: itemDigitalAsset
        };
      }, {} as ResponsiveAssetsInfo);
    } else {
      return {
        [DigitalAssetScreenSize.Full]: digitalAsset,
        [DigitalAssetScreenSize.Compact]: null,
        [DigitalAssetScreenSize.Vertical]: null
      };
    }
  }

  private static getLetterWidth(char: string): number {
    const letterWidthCategory = CfsTemplateUtils.getLetterWideGroup(char);

    switch (letterWidthCategory) {
      case LetterWidthCategory.narrow:
        return 4;
      case LetterWidthCategory.medium:
        return 10;
      case LetterWidthCategory.wide:
        return 15.9;
    }
  }

  private static getLetterWideGroup(char: string): LetterWidthCategory {
    switch (char) {
      case "!":
      case "f":
      case "i":
      case "j":
      case "l":
      case "r":
      case "t":
      case "I":
      case "J": {
        return LetterWidthCategory.narrow;
      }
      case "%":
      case "m":
      case "w":
      case "M":
      case "W": {
        return LetterWidthCategory.wide;
      }
      default: {
        return LetterWidthCategory.medium;
      }
    }
  }
}
