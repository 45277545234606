import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HtmlTemplateDirective } from "./html-template.directive";
import { CfsTemplateUtils, HtmlTemplateOptions, ResponsiveAssetsInfo, TemplatePreviewOptions, TemplatePreviewType } from "clearline-common";
import { DigitalAssetGetDto, DigitalAssetScreenSize, KeyValueAsset, KeyValueDto } from "clearline-api";
import { BehaviorSubject, combineLatest } from "rxjs";
import { filter, map, take } from "rxjs/operators";

@Component({
  selector: "lib-html-template",
  standalone: true,
  imports: [CommonModule, HtmlTemplateDirective],
  templateUrl: "./html-template.component.html",
  styleUrls: ["./html-template.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlTemplateComponent implements OnInit {
  @Input() screenSize: DigitalAssetScreenSize | null = DigitalAssetScreenSize.Full;
  @Input() previewType: TemplatePreviewType = TemplatePreviewType.Active;
  @Input() scalable = true;
  @Input() responsive = false;
  @Input() isLowResolutionScreen = false;
  @Input() isPrintView = false;

  @Input() set options(options: TemplatePreviewOptions | null) {
    if (options) {
      const assetsInfo: ResponsiveAssetsInfo = CfsTemplateUtils.getResponsiveAssetsInfo(options);
      const assetItem: DigitalAssetGetDto | null | undefined = assetsInfo[DigitalAssetScreenSize.Full];
      const itemDefaultParams: KeyValueDto[] = assetItem?.configuration?.defaultParameters || [];
      const allParams: KeyValueAsset = CfsTemplateUtils.getAllKeyValueAssetParams(options, itemDefaultParams);
      const htmlTemplateOptions: HtmlTemplateOptions = CfsTemplateUtils.parseHtmlQrCodeContentAsset(allParams, this.isPrintView);

      this.options$.next(htmlTemplateOptions);
      console.log("htmlTemplateOptions: ", htmlTemplateOptions); // todo: remove after testing
    }
  }

  @Output() printReady = new EventEmitter();

  readonly CFSPreviewType = TemplatePreviewType;

  options$ = new BehaviorSubject<HtmlTemplateOptions>({});
  private logoLoaded$ = new BehaviorSubject<boolean>(false);
  private qrCodeLoaded$ = new BehaviorSubject<boolean>(false);
  private mainImageLoaded$ = new BehaviorSubject<boolean>(false);
  private contentReady$ = combineLatest([this.logoLoaded$, this.qrCodeLoaded$, this.mainImageLoaded$]).pipe(
    map(([logoLoaded, qrCodeLoaded, mainImageLoaded]) => logoLoaded && qrCodeLoaded && mainImageLoaded),
    filter((contentReady) => contentReady),
    take(1)
  );

  ngOnInit(): void {
    this.contentReady$.subscribe(() => {
      if (this.isPrintView) {
        this.printReady.emit();
      }
    });
  }

  onLogoLoaded(): void {
    this.logoLoaded$.next(true);
  }

  onQrCodeLoaded(): void {
    this.qrCodeLoaded$.next(true);
  }

  onMainImageLoaded(): void {
    this.mainImageLoaded$.next(true);
  }
}
