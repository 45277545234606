import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { CfsTemplateMediaConfig, CFSTemplateType, CfsTemplateUtils, defaultCfsTemplateMediaConfig, Template, TemplatePreviewType, VimeoPlayerComponent } from "clearline-common";
import { CommonModule } from "@angular/common";
import { CfsTemplatePreviewComponent } from "./cfs-template-preview";
import { VideoTemplateComponent } from "./video-template";
import { LottieTemplateComponent } from "./lottie-template";
import { HtmlTemplateComponent } from "./html-template";

@Component({
  selector: "lib-cfs-template",
  templateUrl: "./cfs-template.component.html",
  styleUrls: ["./cfs-template.component.scss"],
  standalone: true,
  imports: [CommonModule, CfsTemplatePreviewComponent, VideoTemplateComponent, VimeoPlayerComponent, LottieTemplateComponent, HtmlTemplateComponent]
})
export class CfsTemplateComponent implements OnChanges {
  @Input() template: Template | null = null;
  @Input() index = 0;
  @Input() previewType: TemplatePreviewType = TemplatePreviewType.Active;
  @Input() forPrint = false;
  @Input() isLowResolutionScreen = false;
  @Input() isTestMode = false;

  @Input() set mediaConfig(config: CfsTemplateMediaConfig) {
    this.fullMediaConfig = { ...config, fullscreen: !this.isTestMode, coverParent: this.isTestMode };
  }

  @Output() print = new EventEmitter();
  @Output() templateClick = new EventEmitter();
  @Output() ready = new EventEmitter();

  @ViewChild(CfsTemplatePreviewComponent) previewTemplateComponent?: CfsTemplatePreviewComponent;

  fullMediaConfig: CfsTemplateMediaConfig = defaultCfsTemplateMediaConfig;
  title = "";
  displayTime = 0;
  contentDuration = 0;

  get templateType(): CFSTemplateType | null {
    if (this.previewTemplateComponent) {
      return this.previewTemplateComponent.templateType;
    }

    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["template"] && this.template) {
      const { name } = this.template;

      this.title = name;
      this.displayTime = CfsTemplateUtils.getDisplayTimeByTemplate(this.template);
      this.contentDuration = CfsTemplateUtils.getContentDurationByTemplate(this.template);
    }
  }

  onTemplateClick() {
    this.templateClick.emit();
  }

  play(): void {
    if (this.previewTemplateComponent) {
      this.previewTemplateComponent.play();
    }
  }

  stop(): void {
    if (this.previewTemplateComponent) {
      this.previewTemplateComponent.stop();
    }
  }
}
